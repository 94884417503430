<template>
  <div class="hero-section">
    <!-- <img class="sub-image-1" src="../assets/imgs/yellow_left.png" alt="" /> -->
    <!-- <img class="sub-image-2" src="../assets/imgs/yellow_right.png" alt="" /> -->
    <b-container>
      <b-row>
        <b-col
          cols="12"
          md=""
          class="d-flex flex-column hero-section__main-col"
        >
          <div class="position-relative div">
            <div class="d-flex align-items-end">
              <p class="hero-section__title">
                BESPAREN OP
                <br />

                <span class="hero-section__white">ISOLATIE?</span>
              </p>
            </div>
            <!-- <img
              src="@/assets/imgs/arrow.png"
              class="position-absolute arrow"
              alt="My Image"
            /> -->
            <!-- <h4
              class="mb-md-3 mt-md-0 my-2 pb-1 pt-3 pt-md-0 pb-md-0 hero-section__header"
            >
              Bereid je nu voor op de koude wintermaanden.
            </h4> -->
            <p
              class="my-md-2 my-1 pb-1 pt-1 pt-md-0 pb-md-0 hero-section__text"
            >
              Met onze unieke woningscan ontdek jij:
            </p>
            <div class="d-flex">
              <!-- <div
                class="hero-section__div ml-4 d-flex justify-content-center align-items-center"
              >
                <span class="">
                  <span class="font">30%</span>
                  OVERHEIDSPREMIE
                  <br />
                  IN 2024
                </span>
              </div> -->
              <div>
                <div
                  class="d-md-flex align-items-baseline align-items-md-center"
                >
                  <!-- <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              /> -->
                  <svg
                    class="check-img"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 4C9.38442 4 4 9.38443 4 16C4 22.6156 9.38442 28 16 28C22.6156 28 28 22.6156 28 16C28 9.38443 22.6156 4 16 4ZM16 6C21.5347 6 26 10.4653 26 16C26 21.5347 21.5347 26 16 26C10.4653 26 6 21.5347 6 16C6 10.4653 10.4653 6 16 6ZM20.9492 12L14.6992 18.25L11.4492 15L10.0508 16.4004L14.6992 21.0508L22.3496 13.4004L20.9492 12Z"
                      fill="white"
                    />
                  </svg>

                  <span class="hero-section__text ml-1 ml-md-3">
                    Jouw subsidie opties;
                  </span>
                </div>
                <!-- <div class="d-flex align-items-center mt-1 mt-md-2">
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3"
                >Of je recht hebt op subsidie;</span
              >
            </div> -->

                <div
                  class="d-md-flex align-items-baseline align-items-md-center"
                >
                  <!-- <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              /> -->
                  <svg
                    class="check-img"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 4C9.38442 4 4 9.38443 4 16C4 22.6156 9.38442 28 16 28C22.6156 28 28 22.6156 28 16C28 9.38443 22.6156 4 16 4ZM16 6C21.5347 6 26 10.4653 26 16C26 21.5347 21.5347 26 16 26C10.4653 26 6 21.5347 6 16C6 10.4653 10.4653 6 16 6ZM20.9492 12L14.6992 18.25L11.4492 15L10.0508 16.4004L14.6992 21.0508L22.3496 13.4004L20.9492 12Z"
                      fill="white"
                    />
                  </svg>
                  <span class="hero-section__text ml-1 ml-md-3">
                    Hoeveel jij kan besparen;
                  </span>
                </div>

                <!-- <div
              class="d-flex align-items-baseline align-items-md-center mt-1 mt-md-2"
            >
              <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              />
              <span class="hero-section__text ml-1 ml-md-3">
                zorgt voor gratis en vrijblijvend advies op maat;</span
              >
            </div> -->
                <div
                  class="d-md-flex align-items-baseline align-items-md-center"
                >
                  <!-- <img
                src="@/assets/imgs/checkmark.png"
                class="check-img"
                alt="My Image"
              /> -->
                  <svg
                    class="check-img"
                    width="32"
                    height="32"
                    viewBox="0 0 32 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16 4C9.38442 4 4 9.38443 4 16C4 22.6156 9.38442 28 16 28C22.6156 28 28 22.6156 28 16C28 9.38443 22.6156 4 16 4ZM16 6C21.5347 6 26 10.4653 26 16C26 21.5347 21.5347 26 16 26C10.4653 26 6 21.5347 6 16C6 10.4653 10.4653 6 16 6ZM20.9492 12L14.6992 18.25L11.4492 15L10.0508 16.4004L14.6992 21.0508L22.3496 13.4004L20.9492 12Z"
                      fill="white"
                    />
                  </svg>
                  <span class="hero-section__text ml-1 ml-md-3">
                    De vier beste beschikbare offertes.
                  </span>
                </div>
              </div>
            </div>
            <div class="mt-md-2 mt-1">
              <span class="hero-section__text"> Wist je dat: </span>
              <span class="text-white"
                >je met het vergelijken van offertes tot 40% op de kosten
                bespaart?</span
              >
            </div>

            <!-- <br class="d-flex d-md-none" />
            <br class="d-flex d-md-none" /> -->
            <!-- <div class="d-flex align-items-center pt-2">  -->
            <!-- <div>
                <b-button
                  class="hero-section__btn mt-4 mt-md-0 position-relative"
                  @click="$emit('nextPage')"
                >
                  Controleer

                  <p class="hero-section__sub-text position-absolute">
                    Klaar binnen 1 minuut
                  </p>
                </b-button>
              </div> -->
            <!-- </div> -->
            <!-- <b-form @submit="handleNextPage"> -->
            <!-- <b-row class="flex-column">
                <b-col
                  cols="12"
                  sm="8"
                  md="12"
                  lg="10"
                  class="mt-md-0 d-flex align-items-baseline mt-1"
                > -->
            <!-- <div class="row no-gutters">
                    <div class="">
                      <b-form-input
                        v-model="postdata.zip"
                        @input="handleFieldInput('zip')"
                        placeholder="Postcode"
                        :state="postdata.zip ? zipValidation : null"
                        required
                      ></b-form-input>
                      <b-form-invalid-feedback
                        class="my-1"
                        v-if="!zipValidation"
                      >
                        postcode is niet correct
                      </b-form-invalid-feedback>
                      <p class="text-danger" v-if="setAddress">
                        postcode is niet correct
                      </p>
                    </div>
                  </div> -->
            <div class="d-flex align-items-center pt-2">
              <div>
                <b-button
                  @click="$emit('nextPage')"
                  class="mt-3 hero-section__btn position-relative"
                  type="submit"
                >
                  <!-- <div
                      v-if="loader"
                      class="spinner-border text-info fs--10"
                      role="status"
                    >
                      <span class="sr-only">Loading...</span>
                    </div> -->
                  <span>
                    START DE GRATIS WONINGSCAN!
                    <span class="font-weight-bold">→</span></span
                  >
                  <p class="hero-section__sub-text position-absolute">
                    Klaar binnen 1 minuut
                  </p>
                </b-button>
              </div>
              <div
                class="hero-section__div ml-4 d-flex justify-content-center align-items-center"
              >
                <span class="">
                  <span class="font">30%</span>
                  OVERHEIDSPREMIE
                  <br />
                  IN {{ getCurrentYear() }}
                </span>
              </div>
            </div>
            <!-- </b-col> -->
            <!-- <div class="w-50 mt-4" v-if="setAddress">
                  <b-alert show dismissible class="alert-danger">
                    postcode is niet correct
                  </b-alert>
                </div> -->
            <!-- </b-row> -->
            <!-- </b-form> -->
          </div>
          <b-modal size="lg" hide-footer hide-header v-model="dialog">
            <div v-if="first" class="d-flex flex-column align-items-center">
              <h1 class="text-center modal-text">Even geduld a.u.b.</h1>
              <b-spinner
                class="d-flex align-items-center mt-5"
                style="width: 5rem; height: 5rem"
                label="Loading..."
              ></b-spinner>
            </div>
            <div v-else-if="second">
              <h1 class="text-center modal-text">
                Gefeliciteerd, wij zijn <br />
                beschikbaar in
                <span class="text-green">{{ address.settlement }}</span
                >!
              </h1>
              <h1 class="text-center modal-text mt-4">We sturen je nu door…</h1>
            </div>
          </b-modal>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
// import backgroundImage from "../assets/imgs/herosection.png";
import { mapGetters } from "vuex";

export default {
  name: "HeroSection",
  data() {
    return {
      zipRegex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
      loader: false,
      setAddress: false,
      dialog: false,
      first: false,
      second: false,
    };
  },
  props: {
    postdata: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    handleNextPage(e) {
      e.preventDefault();

      this.loader = true;
      this.dialog = true;
      this.first = true;

      if (this.isValidForm) {
        // Check if the zip code matches the regex pattern
        if (!this.zipRegex.test(this.postdata.zip)) {
          // Invalid zip code format
          this.loader = false;
          this.dialog = false;
          this.first = false;
          this.setAddress = false; // Ensure setAddress is false for invalid zip
          return;
        }

        this.getStreetsByZip({
          zip: this.postdata.zip,
          onSuccess: () => {
            this.loader = false;
            this.dialog = true;
            setTimeout(() => {
              this.first = false;
              this.second = true;
            }, 2000);
            setTimeout(() => {
              this.$emit("nextPage", "post");
              this.dialog = false;
            }, 4000);
          },
          onError: () => {
            this.loader = false;
            setTimeout(() => {
              this.dialog = false;
              this.first = false;
            }, 2000);
            this.setAddress = true;
            setTimeout(() => {
              this.setAddress = true; // Reset setAddress after showing popup
            }, 5000);
          },
        });
      } else {
        // Invalid form, handle accordingly
        // For example: display an error message or prevent further action
        console.error("Invalid form data.");
      }
    },
  },
  computed: {
    ...mapGetters(["getErrorResponses"]),
    zipValidation() {
      // if (this.postdata.zip === "" && !this.getErrorResponses.zip) {
      //   return null;
      // }
      // if (this.getErrorResponses.zip == false) {
      //   return false;
      // } else if (
      //   this.zipRegex.test(this.postdata.zip) ||
      //   this.postdata.zip === ""
      // ) {
      //   return true;
      // }
      return this.zipRegex.test(this.postdata.zip) || this.postdata.zip === "";
    },
    // houseNumberValidation() {
    //   if (
    //     this.postdata.house_number === "" &&
    //     !this.getErrorResponses.house_number
    //   ) {
    //     return null;
    //   }
    //   if (this.getErrorResponses.house_number == false) {
    //     return false;
    //   } else if (
    //     this.houseRegex.test(this.postdata.house_number) ||
    //     this.postdata.house_number === ""
    //   ) {
    //     return true;
    //   }
    //   return (
    //     this.houseRegex.test(this.postdata.house_number) ||
    //     this.postdata.house_number === ""
    //   );
    // },
    isValidForm() {
      return this.postdata.zip && (!this.setAddress || this.zipValidation);
    },
  },
  watch: {
    // "postdata.zip"(newVal, oldVal) {
    //   if (this.isValidForm) {
    //     this.getStreetsByZip({
    //       zip: this.postdata.zip,
    //       onSuccess: () => {
    //         this.setAddress = false;
    //       },
    //       onError: () => {
    //         // this.setAddress = true;
    //         setTimeout(() => {
    //           // this.setAddress = true;
    //         }, 3000);
    //       },
    //     });
    //   }
    //},
  },
};
</script>

<style lang="scss" scoped>
.form-control::placeholder {
  color: #000000 !important;
}

.form-control {
  padding: 1.375rem 1.5rem !important;
  border-radius: 0px !important;
  letter-spacing: 2px !important;
  font-weight: 600;
  @media (max-width: 1024.99px) {
    padding: 1.375rem 0.75rem !important;
  }
}
.text-green {
  color: #5cba01 !important;
}
.modal-text {
  font-size: 40px;
  font-weight: 800;
}
.centered {
  @media (min-width: 991.99px) and (max-width: 3499.99px) {
    position: absolute;
    top: 45%;
    left: 25%;
    bottom: 140px;
    transform: translate(-50%, -58%);
  }
  @media (min-width: 625.99px) and (max-width: 991.99px) {
    left: 50%;
    transform: translate(-50%, 35%);
    top: 50%;
    bottom: 120px;
  }
}
.sub-image-2 {
  height: 15vw;
  position: absolute;
  right: 0;
  @media (max-width: 625.99px) {
    display: none;
  }
}
.sub-image-1 {
  height: 70vh;
  @media (max-width: 625.99px) {
    display: none;
  }
}

.hero-section {
  .font {
    font-size: 20px;
  }
  background-image: url("../../assets/imgs/newIsolatie.png");
  // min-height: 100px !important;
  // background-size: 100% 100%;
  // background-size: cover;
  // padding-top: 70px;
  // padding-bottom: 70px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: center;
  height: 83vh;
  display: flex;
  align-items: center;
  // height: 84vh;
  overflow: hidden;
  // @media (min-width: 1400px) and (max-width: 3499.99px) {
  //   height: 65vh;
  // }
  @media (max-width: 1198.99px) {
    height: 100vh;
  }
  @media (max-width: 625.99px) {
    background-image: url("../../assets/imgs/isolatie-new.jpg");
    height: calc(100vh - 70px);
    display: flex;
    align-items: center;
    // background-position-x: -175px !important;
  }
  &__header {
    font-size: 20px;

    color: #ffffff;
    font-weight: 700;
  }
  &__title {
    // font-size: 64px;
    font-size: 40px;
    // line-height: 58px;
    line-height: 37px;
    text-transform: uppercase;
    color: white !important;
    font-weight: 800;
  }
  &__white {
    color: #ffcc01 !important;
  }

  &__text {
    // font-size: 22px;
    // line-height: 36px;
    font-size: 17px;

    color: #ffffff;
    font-weight: 700;
  }

  &__sub-text {
    // font-size: 17px;
    font-size: 14px;
    letter-spacing: 0px;
    text-transform: none;
    color: #ffffff;
    font-weight: 400;
    right: 15px;
    top: 60px;
  }
  &__div {
    // position: absolute;
    // left: -250px;
    right: 30px;
    top: 300px;
    width: 130px !important;
    height: 130px !important;
    border-radius: 50%;
    background-color: #ffcd02;
    color: #1c1829;
    font-weight: 800;
    font-size: 13px;
    text-align: center;
    @media (max-width: 1024.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
      right: -5px !important;
    }
    @media (max-width: 991.99px) {
      width: 120px !important;
      height: 120px !important;
      font-size: 12px !important;
      right: -135px !important;
    }
    @media (max-width: 575.99px) {
      width: 100px !important;
      height: 100px !important;
      font-size: 12px !important;
      margin-top: 5px !important;
    }
  }
  &__btn {
    // font-size: 17px;
    // width: 50% !important;
    font-size: 17px;
    letter-spacing: 2px;
    text-transform: uppercase;
    color: #000000;
    font-weight: 900;
    background: #ffcd02 !important;
    margin-top: 20px;
    border-radius: 30px;
    padding: 13px 20px;
    border: none !important;
    &:hover,
    &:active {
      background-color: #ffcd02 !important;
      color: #000000;
    }
  }

  .arrow {
    left: -110px !important;
    bottom: 15%;
    rotate: -15deg;
  }

  &__main-col {
    margin-left: 15% !important;
    // padding-left: 55%;
  }
  // @media (min-width: 1499.99px) and (max-width: 2199.99px) {
  //   &__sub-text {
  //     right: 415px;
  //   }
  // }
  // @media (min-width: 2199.99px) and (max-width: 2799.99px) {
  //   &__sub-text {
  //     right: 595px;
  //   }
  // }
  @media (max-width: 1199.99px) {
    &__title {
      font-size: 40px;
    }
    &__div {
      width: 100px;
      height: 100px;
    }
  }
  @media (max-width: 1199.99px) {
    &__sub-text {
      font-size: 10px;
      top: 50px;
      right: 10px;
    }
    &__main-col {
      margin-left: 20% !important;
      // margin-bottom: -33px;
    }
    &__btn {
      font-size: 12px;
      //padding: 13px 0px;
      padding: 14px;
    }
  }
  @media (max-width: 991.99px) {
    &__main-col {
      margin-left: -15% !important;
      padding-left: 35%;
    }
    &__title {
      font-size: 28px;
    }

    &__text {
      font-size: 15px;
    }
    &__sub-text {
      font-size: 12px;
      top: 55px;
    }
    &__btn {
      font-size: 12px;
      padding: 10px 20px;
    }
    .arrow {
      left: -55px !important;
      bottom: 6%;
      width: 40px !important;
    }
  }

  @media (max-width: 767.99px) {
    // padding-top: 30px;
    // padding-bottom: 30px;
    &__main-col {
      margin-left: 0% !important;
      padding-left: 30%;
      margin-top: -40px;
    }
    &__title {
      font-size: 25px;
      line-height: 30px !important;
    }
    &__div {
      left: 230px;
      top: 265px;
      span {
        font-size: 12px;
      }
    }
    &__text {
      font-size: 15px;
    }
    &__sub-text {
      font-size: 10px;
      top: 40px;
      right: 10px;
    }
    &__btn {
      font-size: 10px;
      // padding: 13px 0px;
      letter-spacing: 0.2px;
      // width: 100% !important;
    }
    .arrow {
      // display: none;
    }
    .check-img {
      width: 25px !important;
    }
  }

  @media (max-width: 625.99px) {
    .check-img {
      width: 12px !important;
    }
    &__main-col {
      margin-left: 15% !important;
    }
    &__title {
      font-size: 20px;
      line-height: 25px !important;
    }

    &__text {
      font-size: 13px;
    }
    &__sub-text {
      font-size: 9px;
      top: 60px;
    }
    &__btn {
      font-size: 9px;
      padding: 13px 0px;
      width: 100% !important;
    }
  }

  @media (max-width: 575.99px) {
    padding-top: 30px;
    padding-bottom: 20px;
    .check-img {
      width: 20px !important;
    }
    &__main-col {
      margin-left: 6% !important;
      padding-left: 0%;
      // margin-bottom: 60px;
      .div {
        margin-right: 4rem;
      }
    }
    &__title {
      font-size: 25px;
      // line-height: 18px !important;
      margin-bottom: 5px !important;
    }

    .arrow {
      display: none;

      rotate: 35deg;
      width: 60px;
    }
    &__text {
      // font-size: 13px;
    }
    &__sub-text {
      font-size: 10px;
      top: 50px;
      right: 12px;
    }
    &__btn {
      font-size: 12px;
      padding: 12px 30px;
      margin-top: 10px;
      width: 100% !important;
    }
    &__div span {
      font-size: 10px;
    }
    &__div {
      width: 100px;
      height: 100px;
      position: absolute;
      background-color: #ffcd02;

      top: -30px;
      left: 210px;
    }
  }

  @media (max-width: 374.99px) {
    &__main-col {
      margin-left: 3.5% !important;
      padding-left: 0%;
      // margin-bottom: 60px;

      .div {
        margin-right: 2rem;
      }
    }
    &__title {
      // font-size: 13px;
      // line-height: 15px !important;
    }

    &__text {
      font-size: 12px;
    }
    &__sub-text {
      font-size: 9px;
      top: 46px;
      right: 15px;
    }
    &__btn {
      font-size: 12px;
      padding: 12px 20px;
      width: 100% !important;
    }
    &__div {
      left: 190px;
      top: -20px;
    }
  }
  @media (max-width: 319.99px) {
    padding-top: 67px;
    padding-bottom: 20px;

    &__title {
      font-size: 20px;
    }
    &__div {
      left: 130px;
      top: -55px;
    }
  }
}
</style>
