<template>
  <div class="solar d-flex justify-content-center align-items-center">
    <b-container class="m-0">
      <b-row>
        <b-col
          cols="12"
          md="7"
          class="d-flex justify-content-center flex-column"
        >
          <!-- <p class="solar__title-small mb-0">Kunststof kozijnen</p> -->
          <p class="solar__title">Waarom zonnepanelen + Thuisbatterij?</p>
          <p class="solar__description mt-0">
            Een thuisbatterij in combinatie met zonnepanelen biedt aanzienlijke
            voordelen: zowel voor je portemonnee, als voor het milieu.
          </p>
          <div
            class="d-flex font_sm font-weight-bold align-items-start align-items-md-center ml-10 mt-3 mb-0"
          >
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 4C9.38442 4 4 9.38443 4 16C4 22.6156 9.38442 28 16 28C22.6156 28 28 22.6156 28 16C28 9.38443 22.6156 4 16 4ZM16 6C21.5347 6 26 10.4653 26 16C26 21.5347 21.5347 26 16 26C10.4653 26 6 21.5347 6 16C6 10.4653 10.4653 6 16 6ZM20.9492 12L14.6992 18.25L11.4492 15L10.0508 16.4004L14.6992 21.0508L22.3496 13.4004L20.9492 12Z"
                  fill="#FFCC01"
                />
              </svg>
            </div>

            <div class="last-subtext ml-1 ml-md-2">
              Toekomstbestendigheid: je bent volledig zelfvoorzienend voor een
              langere tijd.
            </div>
          </div>
          <div
            class="d-flex font_sm font-weight-bold align-items-start align-items-md-center ml-10 mt-1 mb-0"
          >
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 4C9.38442 4 4 9.38443 4 16C4 22.6156 9.38442 28 16 28C22.6156 28 28 22.6156 28 16C28 9.38443 22.6156 4 16 4ZM16 6C21.5347 6 26 10.4653 26 16C26 21.5347 21.5347 26 16 26C10.4653 26 6 21.5347 6 16C6 10.4653 10.4653 6 16 6ZM20.9492 12L14.6992 18.25L11.4492 15L10.0508 16.4004L14.6992 21.0508L22.3496 13.4004L20.9492 12Z"
                  fill="#FFCC01"
                />
              </svg>
            </div>

            <div class="last-subtext ml-1 ml-md-2">
              Lagere energiekosten: je verbruikt 100% van de zelf opgewekte
              energie
            </div>
          </div>
          <div
            class="d-flex font_sm font-weight-bold align-items-start align-items-md-center ml-10 mt-1 mb-0"
          >
            <div>
              <svg
                width="32"
                height="32"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M16 4C9.38442 4 4 9.38443 4 16C4 22.6156 9.38442 28 16 28C22.6156 28 28 22.6156 28 16C28 9.38443 22.6156 4 16 4ZM16 6C21.5347 6 26 10.4653 26 16C26 21.5347 21.5347 26 16 26C10.4653 26 6 21.5347 6 16C6 10.4653 10.4653 6 16 6ZM20.9492 12L14.6992 18.25L11.4492 15L10.0508 16.4004L14.6992 21.0508L22.3496 13.4004L20.9492 12Z"
                  fill="#FFCC01"
                />
              </svg>
            </div>

            <div class="last-subtext ml-1 ml-md-2">
              Korting: je krijgt in {{ getCurrentYear() }} 21% belastingkorting!
            </div>
          </div>
          <p class="solar__description text-justify mt-4">
            Daarbij helpen wij je ook enorm te besparen op de aanschafkosten.
            Met het vergelijken van offertes bespaar je doorgaans tot 40%!
          </p>
        </b-col>
        <!-- <b-col cols="1" md="1"></b-col> -->
        <b-col
          cols="12"
          md="5"
          class="mt-md-0 mt-2 d-flex flex-column align-items-center justify-content-center position-relative"
        >
          <!-- <p class="solar__text">
            30% alleen van toepassing als u gebruik maakt van meerdere
            isolerende/verduurzamings maatregelen die onder ISDE vallen
          </p> -->
          <img
            src="@/assets/imgs/thuisbattarij_solarpannel.png"
            class="img-fluid"
            alt="Solar Image"
            width="100%"
            height="auto"
          />
          <!-- <div class="solar__panel-text">
            <p class="mb-0 solar__panel-text-title">1.210.679</p>
            <p class="mb-0 solar__panel-text-sub-title">
              mensen gingen je voor
            </p>
          </div> -->
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
export default {
  name: "SolarPanel",
  methods: {
    getCurrentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style lang="scss" scoped>
.solar {
  padding-top: 70px;
  padding-bottom: 70px;
  background: #ffffff;

  &__title-small {
    font-size: 14px;
    color: #ffcc01;
    font-weight: 600;
  }
  .font_sm {
    font-size: 15px;
  }
  &__title {
    font-size: 29px;
    color: #1d1729;
    font-weight: 700;
    line-height: 40px;
  }

  &__description {
    font-size: 16px;

    // letter-spacing: 2px;
    // line-height: 33px;
    line-height: 20px;
    color: #1d1729;
    font-weight: 600;
  }

  &__panel {
    &-text {
      position: absolute;
      text-align: center;
      right: 44px;
      top: 88px;

      &-title {
        // font-size: 36px;
        font-size: 25px;

        color: #1d1729;
        font-weight: 900;
      }

      &-sub-title {
        // font-size: 16px;
        font-size: 12px;
        color: #1d1729;
        font-weight: 500;
      }
    }

    // &-text > p {
    //   font-size: calc(1vw + 1vh);
    //   margin: 0;
    // }
  }
  &__text {
    font-size: 17px;
    color: #1d1729;
  }
  @media (max-width: 1199.99px) {
    &__title-small {
      font-size: 14px;
    }

    &__title {
      font-size: 35px;
      line-height: 40px;
    }

    &__description {
      font-size: 16px;
      line-height: 25px;
    }
  }
  @media (max-width: 992.99px) {
    &__title-small {
      font-size: 13px;
    }

    &__title {
      font-size: 30px;
    }

    &__description {
      font-size: 14px;
    }
  }
  @media (max-width: 767.99px) {
    padding-top: 30px;
    padding-bottom: 30px;
    &__title-small {
      font-size: 12px;
    }

    &__title {
      font-size: 25px;
      line-height: 30px;
    }

    &__description {
      font-size: 12px;
      line-height: 20px;
    }
  }
  @media (max-width: 577.99px) {
    &__title-small {
      font-size: 11px;
    }

    &__title {
      font-size: 20px;
      line-height: 20px;
    }

    &__description {
      font-size: 11px;
      line-height: 15px;
    }
  }
  svg {
    height: 24px;
    width: 24px;
  }
}
</style>
